<template>
  <div>
    <v-sheet
      class="border-secondary rounded-xl py-4 py-sm-7 px-4 px-sm-16"
      width="100%"
      max-width="500px"
    >
      <a v-bind="redirectHref" target="_blank">
        <v-img
          :src="getAdNetworkImages.theme_white_logo"
          :lazy-src="getAdNetworkImages.theme_white_logo"
          contain
          class="mx-auto"
          width="326"
          height="80"
          max-height="80"
        />
      </a>
      <v-progress-linear v-if="loading" class="mt-2" indeterminate color="primary" />
      <div class="px-4 mt-4">
        <v-form>
          <c-text-field
            :id="seleniumIds.SELENIUM_TEST_LOGIN_EMAIL_FIELD"
            autocomplete="email"
            label="Email"
            name="username"
            required
            :error-messages="errors.email"
            :value="email"
            aria-label="Email"
            @input="setEmail($event)"
            @focus="resetEmailErrors()"
            @keydown.enter="handleFormAction('email')"
          />

          <c-text-field
            :id="seleniumIds.SELENIUM_TEST_LOGIN_PASSWORD_FIELD"
            ref="password"
            autocomplete="current-password"
            :label="$t('login.password')"
            type="password"
            aria-label="password"
            :error-messages="errors.password"
            :value="password"
            @input="setPassword($event)"
            @focus="resetPasswordErrors()"
            @keydown.enter="handleFormAction('password')"
          />

          <login-google-authentication
            v-if="google2faIsShown"
            :is-shown="google2faIsShown"
            @resend-code="$refs.captcha.performVerifiedAction()"
            @close-dialog="google2faIsShown = false"
          />

          <confirmation-code-input
            ref="confirmationCodeInput"
            @resend-code="$refs.captcha.performVerifiedAction()"
            @keydown-enter="handleFormAction('code')"
          />
        </v-form>

        <captcha ref="captcha" :error-messages="errors['g-recaptcha-response']" @verified="loginHandler($event)">
          <c-btn
            class="white--text"
            color="primary"
            :disabled="loading"
            large
            block
            :label="$t('login.login')"
            @click="handleFormAction()"
          />
        </captcha>

        <div class="text-body-2 text-center mt-3">
          {{ $t('login.forgot_password') }}
          <v-hover v-slot="{ hover }">
            <button
              :class="[
                'py-2 px-1 text-decoration-underline',
                hover ? 'primary-lighten--text ' : 'primary--text'
              ]"
              type="button"
              @click="setResetDialogIsShown(true)"
            >
              {{ $t('login.restore_password') }}
            </button>
          </v-hover>
        </div>

        <div class="d-flex justify-space-between align-center mt-4">
          <v-divider />
          <span class="text-body-2 px-5 text-uppercase">
            {{ $t('login.or') }}
          </span>
          <v-divider />
        </div>

        <v-layout cols="12" align-center justify-space-between>
          <div class="text-center mt-2 mb-2">
            <c-btn
              color="primary"
              small
              :to="{ name: 'Register' }"
              :label="$t('login.create_new_account')"
            />
          </div>

          <google-auth-button />
        </v-layout>
      </div>
    </v-sheet>

    <reset-password-dialog :value="resetDialogIsShown" @close="setResetDialogIsShown(false)" />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import Cookies from 'js-cookie'
  import gtmPush from '@/services/utils/gtm-push.js'
  import forgetUserFromCookie from '@/mixins/forgetUserFromCookie.js'
  import { removeCookie } from '@/cookie-queries.js'
  import ResetPasswordDialog from './ResetPasswordDialog.vue'
  import ConfirmationCodeInput from './ConfirmationCodeInput.vue'
  import Captcha from '@/components/Captcha.vue'
  import routeNames from '@/types/route-names.js'
  import guardSections from '@/types/guard-sections.js'
  import handleErrors from '@/services/handleErrors.js'
  import CodeRequiredLoginError from '@/services/classes/CodeRequiredLoginError.js'
  import GoogleAuthButton from '@/components/GoogleAuthButton.vue'

  export default {
    name: 'Login',
    components: {
      GoogleAuthButton,
      LoginGoogleAuthentication: () => import('@/views/Login/LoginGoogleAuthentication.vue'),
      Captcha,
      ResetPasswordDialog,
      ConfirmationCodeInput,
      CTextField,
      CBtn
    },
    mixins: [forgetUserFromCookie],
    data() {
      return {
        google2faIsShown: false,
        seleniumIds
      }
    },
    computed: {
      ...mapState('login', [
        'loading',
        'errors',
        'email',
        'password',
        'code',
        'resetDialogIsShown',
        'authenticationMethod'
      ]),
      ...mapGetters('login', ['codeFieldIsShown', 'loginParams']),
      ...mapGetters('settings', ['getAdNetworkImages', 'helpUrls']),
      ...mapState('settings', ['adNetwork']),
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      redirectHref() {
        return { href: this.helpUrls.landing || false }
      },
      adNetworkHasSectionMarketplace() {
        return this.adNetwork.guardSections.some(({ name }) => name === guardSections.FLAT_DEALS)
      },
      routeParamsForSectionMarketplace() {
        return {
          marketplace: { name: routeNames.MARKETPLACE, query: this.$route.query },
          'flat-deal': { name: routeNames.FLAT_DEAL, params: { id: this.$route.query.id }, query: this.$route.query }
        }[this.$route.query.pageTo]
      },
      redirectIsMarketplace() {
        return !!(this.adNetworkHasSectionMarketplace && this.$route.query.pageTo)
      }
    },
    watch: {
      email() {
        this.resetConfirmation()
      }
    },
    created() {
      this.resetState()
      this.authenticateOnQueryToken()
    },
    methods: {
      ...mapActions('settings', ['fetchSettings']),
      ...mapActions('login', [
        'setEmail',
        'setPassword',
        'setCode',
        'setResetDialogIsShown',
        'resetConfirmation',
        'resetState',
        'resetEmailErrors',
        'resetPasswordErrors',
        'setAuthenticationMethod',
        'setIsExpired',
        'setCodeSentAt',
        'setCodeExpirationTime',
        'setErrors',
        'setGoogleCode',
        'setLoading'
      ]),
      async redirectAfterRegister() {
        removeCookie('registration_params')
        removeCookie('affiliate_code')

        const abTestConfirmEmail = !!sessionStorage.getItem('ab_test_confirm_email')
        const abTestConfirmEmailGroup = Cookies.get('ab_test_confirm_email_group') === '1'
        if (abTestConfirmEmail && abTestConfirmEmailGroup && this.$auth.user.status !== 'confirmed') {
          await this.$router.push({ name: routeNames.REQUEST_CONFIRM_EMAIL })
          return
        }

        const nextRoutingPath = sessionStorage.getItem('next_routing_path')
        sessionStorage.removeItem('next_routing_path')
        if (this.redirectIsMarketplace && this.routeParamsForSectionMarketplace) {
          await this.$router.push(this.routeParamsForSectionMarketplace)
          return
        }
        const route = nextRoutingPath ? { path: nextRoutingPath } : { name: routeNames.MAIN }
        await this.$router.push(route)
      },
      async loginHandler(recaptcha) {
        this.setLoading(true)
        try {
          await this.$auth.login({ ...this.loginParams, 'g-recaptcha-response': recaptcha })
          await Promise.all([
            this.$auth.fetchUser(),
            this.fetchSettings(this.$auth.isAuth)
          ])
          gtmPush({
            event: GTM_EVENTS.LOG_FORM_SUBMITED_SUCCESSFULLY,
            custom_map: { dimension3: 'UserReg' },
            UserReg: this.$auth.user?.id,
            user_id: this.$auth.user?.id
          })
          await this.redirectAfterRegister()

          this.resetState()
        } catch (error) {
          this.setGoogleCode('')
          if (error instanceof CodeRequiredLoginError) {
            this.setAuthenticationMethod(error.authenticationMethod)

            if (this.authenticationMethod === 'google') {
              this.google2faIsShown = true
              return
            }
            this.setCodeExpirationTime(error.codeExpirationTime)
            this.setCodeSentAt(error.codeSentAt)
            this.setIsExpired(false)
            return
          }
          const errors = handleErrors(error)

          this.setErrors(errors)
        } finally {
          this.setLoading(false)
        }
      },
      handleFormAction(field) {
        switch (field) {
        case 'email':
          this.$refs.password.focus()
          break
        case 'password':
          if (this.codeFieldIsShown) {
            this.$refs.confirmationCodeInput.focus()
          } else {
            this.$refs.captcha.performVerifiedAction()
          }
          break
        case 'code':
          this.$refs.captcha.performVerifiedAction()
          break
        default:
          this.$refs.captcha.performVerifiedAction()
        }
      },
      authenticateOnQueryToken() {
        if (this.$route.query.token) {
          localStorage.auth_token_default = this.$route.query.token
          this.$route.query.token = undefined
        }
      }
    }
  }
</script>
